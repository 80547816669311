import { useMutation } from '@tanstack/react-query';
import { AdditionalMutationOptions } from '../types';
import { usePostHog } from 'posthog-js/react';
import { useChatMessagesInfiniteQuery } from './queries';

export function useLikeMessageMutation<S extends string>(
  chatId: S,
  messageId: S,
  like: boolean,
  options: AdditionalMutationOptions<boolean, void, ['message', string, 'like']> = {},
) {
  const posthog = usePostHog();
  const { data } = useChatMessagesInfiniteQuery(chatId);

  return useMutation({
    ...options,
    mutationKey: ['message', messageId, 'like'],
    async mutationFn() {
      try {
        const messages = data?.pages[0].items || [];
        const messageIdx = messages.findIndex((m) => m.id === messageId);

        posthog?.capture(`Answer ${like ? 'Liked' : 'Disliked'}`, {
          chat_id: chatId,
          feedback: { like, dislike: !like },
          question: messageIdx > 0 ? messages[messageIdx - 1].content : 'first message',
          answer: messages[messageIdx]?.content,
        });

        return true;
      } catch (error) {
        return false;
      }
    },
  });
}
