import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdditionalMutationOptions, AssistantConversationStarters } from '../../types';
import { useRequest } from '../../request';
import { InsertConversationStarter, UpdateConversationStarter } from './types';
import { adjust, findIndex, update, propEq, mergeLeft, without, append } from 'ramda';

export function useAddConversationStarterMutation(
  assistantId: string | undefined,
  {
    onSuccess,
    onMutate,
    ...options
  }: AdditionalMutationOptions<
    any,
    InsertConversationStarter,
    readonly ['assistant', string, 'conversation-starters', 'create']
  > = {},
) {
  const client = useQueryClient();
  const request = useRequest();

  return useMutation({
    ...options,
    mutationKey: ['assistant', assistantId, 'conversation-starters', 'update'],
    async mutationFn(variables) {
      return request(`/api/v2/assistants/${assistantId}/conversation-starters`, {
        method: 'POST',
        body: JSON.stringify(variables),
      });
    },
    onMutate(variables) {
      client.setQueryData(['assistant', assistantId, 'conversation-starters'], append(variables));
      return onMutate?.(variables);
    },
    onSuccess(data, variables, context) {
      client.invalidateQueries({
        queryKey: ['assistant', assistantId, 'conversation-starters'],
      });
      return onSuccess?.(data, variables, context);
    },
  });
}

export function useUpdateConversationStartersMutation(
  assistantId: string | undefined,
  {
    onSuccess,
    onMutate,
    ...options
  }: AdditionalMutationOptions<
    any,
    UpdateConversationStarter,
    readonly ['assistant', string, 'conversation-starters', 'update']
  > = {},
) {
  const client = useQueryClient();
  const request = useRequest();

  return useMutation({
    ...options,
    mutationKey: ['assistant', assistantId, 'conversation-starters', 'update'],
    async mutationFn(variables) {
      return request(`/api/v2/assistants/${assistantId}/conversation-starters/${variables.id}`, {
        method: 'PATCH',
        body: JSON.stringify(variables),
      });
    },
    onMutate(variables) {
      client.setQueryData<AssistantConversationStarters[]>(
        ['assistant', assistantId, 'conversation-starters'],
        (cache = []) => {
          const index = findIndex(propEq('id', variables.id), cache);
          if (index > -1 && cache) {
            return adjust(index, mergeLeft(variables), cache);
          }
          return cache;
        },
      );
      return onMutate?.(variables);
    },
    onSuccess(data, variables, context) {
      client.invalidateQueries({
        queryKey: ['assistant', assistantId, 'conversation-starters'],
      });
      return onSuccess?.(data, variables, context);
    },
  });
}

/**
 * Deletes a single conversation starter
 * @param assistantId
 * @param param1
 * @returns
 */
export function useDeleteConversationStarterMutation(
  assistantId: string | undefined,
  {
    onMutate,
    onSuccess,
    ...options
  }: AdditionalMutationOptions<
    AssistantConversationStarters,
    { id: number },
    readonly ['assistant', string, 'conversation-starters', 'delete']
  > = {},
) {
  const client = useQueryClient();
  const request = useRequest();

  return useMutation({
    ...options,
    mutationKey: ['assistant', assistantId, 'conversation-starters', 'delete'],
    async mutationFn({ id }) {
      return request(`/api/v2/assistants/${assistantId}/conversation-starters/${id}`, {
        method: 'DELETE',
      });
    },
    onMutate(variables) {
      client.setQueryData<AssistantConversationStarters[]>(
        ['assistant', assistantId, 'conversation-starters'],
        (cache) => {
          if (cache) {
            return without([variables], cache);
          }
          return cache;
        },
      );
      return onMutate?.(variables);
    },
    onSuccess(data, variables, context) {
      client.invalidateQueries({
        queryKey: ['assistant', assistantId, 'conversation-starters'],
      });
      return onSuccess?.(data, variables, context);
    },
  });
}
