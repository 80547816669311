import { useQuery } from '@tanstack/react-query';
import { AdditionalQueryOptions, Personalization } from '../types';
import { useRequest } from '../request';

export function useUserPersonalizationQuery(
  options: AdditionalQueryOptions<Personalization, ['user', 'personalization']> = {},
) {
  const request = useRequest();
  return useQuery({
    ...options,
    queryKey: ['user', 'personalization'],
    async queryFn() {
      const { data } = await request<{ data: Personalization }>('/api/v2/personalization');
      return data;
    },
  });
}
