import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { DropdownMenuGroup } from '@radix-ui/react-dropdown-menu';
import { cn } from '../utils';

interface DropdownGroupProps extends ComponentPropsWithoutRef<typeof DropdownMenuGroup> {}
type DropdownGroupRef = ElementRef<typeof DropdownMenuGroup>;

export const DropdownGroup = forwardRef<DropdownGroupRef, DropdownGroupProps>(({ className, ...props }, ref) => {
  return <DropdownMenuGroup {...props} className={cn('my-[2px]', 'pb-[2px]', className)} ref={ref} />;
});

DropdownGroup.displayName = 'DropdownGroup';
