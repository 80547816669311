'use client';

import { type ElementRef, type ComponentPropsWithoutRef, forwardRef } from 'react';
import { Root } from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'packages/ui/src/utils';
import { useFormField } from '../form-field/form-field';

const labelVariants = cva('text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70');

type Props = ComponentPropsWithoutRef<typeof Root> & VariantProps<typeof labelVariants>;

export const Label = forwardRef<ElementRef<typeof Root>, Props>(({ className, htmlFor, ...props }, ref) => {
  const formField = useFormField();
  return (
    <Root ref={ref} className={cn(labelVariants(), className)} {...props} htmlFor={htmlFor ?? formField?.inputId} />
  );
});

Label.displayName = Root.displayName;
