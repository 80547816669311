import React, { ComponentPropsWithRef, forwardRef } from 'react';
import { LinkIcon } from 'lucide-react';
import { Button, ButtonProps } from '../button';
import invariant from 'tiny-invariant';

interface FileUploadProps extends ComponentPropsWithRef<'input'>, Pick<ButtonProps, 'variant'> {}

/**
 * File upload component
 * Note that this wraps an `input[type="file"]` element
 */
export const FileUpload = forwardRef<HTMLInputElement, FileUploadProps>(
  ({ type = 'file', variant = 'outline', children, ...props }, ref) => {
    invariant(
      type === 'file',
      'FileUpload only supports type="file"\nYou might see unexpected results by changing the type.',
    );
    return (
      <Button variant={variant} asChild>
        <div className="relative cursor-pointer px-8">
          <input
            {...props}
            type={type}
            className="absolute bottom-0 left-0 right-0 top-0 h-full w-full cursor-pointer opacity-0"
            ref={ref}
          />
          <div className="left-0 right-0 flex h-full w-full cursor-pointer items-center justify-center gap-1 py-1">
            <LinkIcon height="100%" />
            <span className="pr-2">{children}</span>
          </div>
        </div>
      </Button>
    );
  },
);

FileUpload.displayName = 'FileUpload';
