interface UploadOptions {
  readonly headers?: Record<string, string>;
  onError?(request: XMLHttpRequest): void;
  onSuccess?(request: XMLHttpRequest): void;
  onSettled?(request: XMLHttpRequest): void;
  onProgress?(progress: number, request: XMLHttpRequest): void;
  onStart?(request: XMLHttpRequest): void;
}

export async function upload(
  url: string,
  file: File,
  { onError, onStart, onSuccess, onSettled, onProgress, headers }: UploadOptions = {},
) {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', url);

  xhr.upload.onprogress = ({ loaded, total, lengthComputable }) => {
    if (lengthComputable) {
      onProgress?.((loaded / total) * 100, xhr);
    }
  };

  const promise = new Promise((resolve, reject) => {
    xhr.onload = function onLoad() {
      if (xhr.status >= 200 && xhr.status <= 400) {
        onSuccess?.(xhr);
      }
      resolve(xhr);
    };

    xhr.onreadystatechange = function onReadyStateChange(event) {
      if (xhr.readyState === xhr.OPENED) {
        onStart?.(xhr);
      }
      if (xhr.readyState === xhr.DONE) {
        onSettled?.(xhr);
        if (xhr.status >= 200 && xhr.status <= 400) {
          resolve(xhr);
        } else {
          reject(new Error(xhr.statusText));
        }
      }
    };

    xhr.onerror = function handleError() {
      onError?.(xhr);
      reject(new Error(xhr.statusText));
    };
  });

  for (const [key, value] of Object.entries(headers ?? {})) {
    xhr.setRequestHeader(key, value);
  }

  xhr.send(file);

  return promise;
}
