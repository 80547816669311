import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { DropdownMenuSeparator } from '@radix-ui/react-dropdown-menu';
import { cn } from '../utils';

type TriggerProps = ComponentPropsWithoutRef<typeof DropdownMenuSeparator>;
type TriggerRef = ElementRef<typeof DropdownMenuSeparator>;

export const DropdownSeparator = forwardRef<TriggerRef, TriggerProps>(({ className, ...props }, ref) => {
  return (
    <DropdownMenuSeparator
      {...props}
      ref={ref}
      className={cn('bg-input', 'h-[1px]', 'w-full', 'px-[-2px]', className)}
    />
  );
});

DropdownSeparator.displayName = 'DropdownSeparator';
