import { useMutation } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import useCsrfTokenHeader from '~/core/hooks/use-csrf-token-header';
import { request } from '../util';

interface AcceptInviteParams {
  onSuccess?: (data?: { verifyEmail: boolean }) => void;
  onError?: (error: Error) => void;
}

export const useAcceptInvite = ({ onSuccess, onError }: AcceptInviteParams) => {
  const csrf = useCsrfTokenHeader();
  const pathName = usePathname();

  // This could be either /invite/[:code]/accept for existing user
  // or /onboarding/accept for user joining for the first time
  const path = [pathName, 'accept'].join('/');

  return useMutation({
    mutationKey: ['invite', 'accept'],
    mutationFn: async ({ code, userId }: { code: string; userId: string }) => {
      return await request<{ verifyEmail: boolean }>(
        {
          pathname: path,
        },
        {
          method: 'POST',
          headers: csrf,
          body: JSON.stringify({
            userId: userId,
            code: code,
          }),
        },
      );
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onError: (error: Error) => {
      onError?.(error);
    },
  });
};
