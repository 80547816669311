'use client';

import { Title } from '@radix-ui/react-dialog';
import React, { type ElementRef, type ComponentPropsWithoutRef, forwardRef } from 'react';
import { cn } from '../utils';

export const DialogTitle = forwardRef<ElementRef<typeof Title>, ComponentPropsWithoutRef<typeof Title>>(
  ({ className, ...props }, ref) => (
    <Title
      ref={ref}
      className={cn('text-[1.125rem]', 'font-semibold', 'leading-none', 'tracking-tight', className)}
      {...props}
    />
  ),
);

DialogTitle.displayName = Title.displayName;
