'use client';

import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Root, Thumb } from '@radix-ui/react-switch';
import { SwitchThumb } from '@portal/icons';
import { cn } from '../utils';

export type SwitchRef = ElementRef<typeof Root>;
export type SwitchProps = ComponentPropsWithoutRef<typeof Root> & { size?: 'lg' | 'default' };

type IconProps = ComponentPropsWithoutRef<'svg'> & Pick<SwitchProps, 'size'>;

const ThumbIcon = forwardRef<SVGSVGElement, IconProps>(({ size = 'default', ...props }, ref) => {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ref={ref}>
      <g filter="url(#filter0_d_232_19995)">
        <circle cx="12" cy="12" r="12" fill="white" />
      </g>
      <rect x="9" rx="0.5" y="4" height="16" width="1" className="rounded-full opacity-50" />
      <rect x="14" rx="0.5" y="4" height="16" width="1" className="rounded-full opacity-50" />
      <defs>
        <filter
          id="filter0_d_232_19995"
          x="0"
          y="0"
          width="24"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_232_19995" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_232_19995" result="shape" />
        </filter>
      </defs>
    </svg>
  );
});

ThumbIcon.displayName = 'ThumbIcon';

/**
 * Switch component
 * A component for toggling a a binary state
 * Based on the Switch component from radix ui
 *
 * @docs https://www.radix-ui.com/themes/docs/components/switch
 * @design https://www.figma.com/design/n1Yhyp32Y7jihjRCVcX0nx/New-UI?node-id=232-19985&t=cR6yynHdLwMlR4fW-4
 *
 * @example
 * ```typescript
 * function Settings({ userSettings }) {
 *   const methods = useForm({
 *     values: {
 *       darkMode: userSettings.darkMode,
 *     },
 *   });
 *   return (
 *     <Form>
 *       <FormField name="darkMode">
 *         <Label>Dark mode</Label>
 *         <Switch />
 *       </FormField>
 *       <Submit>Save Changes</Submit>
 *     </Form>
 *   );
 * }
 * ```
 */
export const Switch = forwardRef<SwitchRef, SwitchProps>(({ className, size = 'default', ...props }, ref) => (
  <Root
    className={cn(
      'data-[state=checked]:bg-primary',
      'peer',
      'inline-flex',
      'h-8',
      'w-16',
      'shrink-0',
      'cursor-pointer',
      'items-center',
      'rounded-full',
      'border-2',
      'border-transparent',
      'transition-colors',
      'focus-visible:outline-none',
      'focus-visible:ring-2',
      'focus-visible:ring-ring',
      'focus-visible:ring-offset-2',
      'focus-visible:ring-offset-background',
      'disabled:cursor-not-allowed',
      'disabled:opacity-50',
      'data-[state=unchecked]:bg-input',
      'data-[state=checked]:bg-green-500',
      {
        'w-8 h-4': size === 'default',
        'w-14 h-8': size === 'lg',
      },
      className,
    )}
    {...props}
    ref={ref}
  >
    <Thumb
      asChild
      className={cn(
        'pointer-events-none',
        'block',
        'h-3',
        'w-3',
        'rounded-full',
        'bg-background',
        'shadow-lg',
        'ring-0',
        'transition-transform',
        'data-[state=checked]:translate-x-6',
        'data-[state=unchecked]:translate-x-0',
        'data-[state=checked]:fill-green-500',
        'data-[state=unchecked]:fill-input',
        'data-[state=checked]:text-green-500',
        {
          'h-5 w-5 data-[state=checked]:translate-x-7 data-[state=unchecked]:translate-x-1': size === 'lg',
          'h-3 w-3 data-[state=checked]:translate-x-4': size === 'default',
        },
      )}
    >
      <ThumbIcon />
    </Thumb>
  </Root>
));

Switch.displayName = Root.displayName;
