'use client';

import { forwardRef } from 'react';
import { useFormField } from '../form-field';
import { cn } from '../utils';
import { useController, useFormContext } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, type, id, ...props }, ref) => {
  const formField = useFormField();
  const control = useFormContext();
  const controller = useController({ name: formField?.name as string, control: control.control });
  const field = controller?.field ?? {};
  const fieldState = controller?.fieldState ?? {};
  const inputRef = mergeRefs([ref, field?.ref]);

  return (
    <input
      {...field}
      type={type}
      className={cn(
        'flex',
        'h-10',
        'w-full',
        'rounded-md',
        'border',
        'border-input',
        'bg-background',
        'px-3',
        'py-2',
        'text-sm',
        'ring-offset-background',
        'placeholder:text-muted-foreground',
        'focus-visible:outline-none',
        'focus-visible:ring-2',
        'focus-visible:ring-ring',
        'focus-visible:ring-offset-2',
        'disabled:cursor-not-allowed',
        'disabled:opacity-50',
        className,
      )}
      aria-busy={fieldState?.isValidating}
      aria-invalid={fieldState?.invalid}
      {...props}
      id={id ?? formField?.inputId}
      ref={inputRef}
    />
  );
});

Input.displayName = 'Input';
