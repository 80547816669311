'use client';

export * from './dialog-content';
export * from './dialog-overlay';
export * from './dialog-title';
export * from './dialog-footer';
export * from './dialog-header';
export * from './dialog-portal';
export * from './dialog-description';

export { Root as Dialog, Trigger as DialogTrigger, Close as DialogClose } from '@radix-ui/react-dialog';
