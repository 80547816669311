'use client';

import React from 'react';
import { ActiveMenuItem, useSidebarStore } from '~/stores/my-wandy-sidebar';
import { Button } from './button';
import { MoveLeft } from 'packages/icons/src';

export const ButtonBackMobile = ({
  activeMenuItem,
  onClick,
}: {
  activeMenuItem: ActiveMenuItem;
  onClick?: () => void;
}) => {
  const { setActiveMenuItem } = useSidebarStore();

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={() => (onClick ? onClick() : setActiveMenuItem(activeMenuItem))}
      data-pr="mobileBackBtn"
    >
      <MoveLeft className="-mx-1 w-6 h-6 cursor-pointer font-bold text-lg" />
    </Button>
  );
};
