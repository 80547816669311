import React, { ComponentProps, forwardRef } from 'react';
import { Portal } from '@radix-ui/react-dialog';
import { ThemeProvider } from '../theme';

type DialogPortalProps = ComponentProps<typeof Portal>;

export const DialogPortal = forwardRef<HTMLDivElement, DialogPortalProps>(({ children, ...props }, ref) => {
  return (
    <Portal {...props}>
      <ThemeProvider ref={ref}>{children}</ThemeProvider>
    </Portal>
  );
});

DialogPortal.displayName = Portal.displayName;
