'use client';

import React, { type ElementRef, type ComponentPropsWithoutRef, forwardRef } from 'react';
import { Overlay } from '@radix-ui/react-dialog';
import { cn } from '../utils';

export const DialogOverlay = forwardRef<ElementRef<typeof Overlay>, ComponentPropsWithoutRef<typeof Overlay>>(
  ({ className, ...props }, ref) => (
    <Overlay
      ref={ref}
      className={cn(
        'bg-brand-dialog-overlay',
        'fixed',
        'inset-0',
        'z-50',
        'data-[state=open]:animate-in',
        'data-[state=closed]:animate-out',
        'data-[state=closed]:fade-out-0',
        'data-[state=open]:fade-in-0',
        className,
      )}
      {...props}
    />
  ),
);

DialogOverlay.displayName = Overlay.displayName;
