'use client';

import { ComponentPropsWithRef, forwardRef } from 'react';
import { useFormField } from '../form-field';
import { cn } from '../utils';
import { useController } from 'react-hook-form';
import { mergeRefs } from 'react-merge-refs';

interface TextAreaProps extends ComponentPropsWithRef<'textarea'> {}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({ className, id, rows = 3, ...props }, ref) => {
  const formField = useFormField();
  const { field, fieldState } = useController({ name: formField?.name as string });
  const textareaRef = mergeRefs([ref, field?.ref]);

  return (
    <textarea
      {...field}
      className={cn(
        'flex',
        'w-full',
        'rounded-md',
        'border',
        'border-input',
        'bg-background',
        'px-2',
        'py-4',
        'text-sm',
        'ring-offset-background',
        'placeholder:text-muted-foreground',
        'focus-visible:outline-none',
        'focus-visible:ring-2',
        'focus-visible:ring-ring',
        'focus-visible:ring-offset-2',
        'disabled:cursor-not-allowed',
        'disabled:opacity-50',
        'rounded-md',
        className,
      )}
      rows={rows}
      aria-busy={fieldState?.isValidating}
      aria-invalid={fieldState?.invalid}
      {...props}
      id={id ?? formField?.inputId}
      ref={textareaRef}
    />
  );
});

TextArea.displayName = 'TextArea';
