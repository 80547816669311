'use client';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  AdditionalMutationOptions,
  AssistantDetails,
  AssistantDetailsUpdate,
  AssistantUpdate,
  Assistant,
  AssistantConversationStarters,
} from '../types';
import { request } from '../util';
import { mergeLeft } from 'ramda';
import useCsrfTokenHeader from '~/core/hooks/use-csrf-token-header';
import { useRef } from 'react';
import { AssistantUpdateRequest } from 'packages/chat-sdk/dist';
import AssistantPermission from '~/lib/organizations/types/assistant-permission';
import { useRequest } from '../request';

/**
 * Updates the assistant details.
 * Invalidates any associated `assistant details` queries
 * @param param0
 * @returns
 */
export function useUpdateAssistantDetailsMutation({
  onMutate,
  onError,
  onSuccess,
  ...options
}: AdditionalMutationOptions<AssistantDetailsUpdate, AssistantDetailsUpdate, ['assistant', 'details', 'update']> = {}) {
  const cache = useRef<AssistantDetails | null>(null);
  const csrf = useCsrfTokenHeader();
  const client = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: ['assistant', 'details', 'update'],
    async mutationFn(variables) {
      const headers = new Headers(csrf);
      headers.set('Content-Type', 'application/json');
      return request<AssistantDetails>(
        {
          pathname: `/api/v2/assistants/${variables.assistant_id}/details`,
        },
        {
          method: 'PATCH',
          body: JSON.stringify(variables),
          headers,
        },
      );
    },
    onMutate(variables) {
      cache.current = client.getQueryData<AssistantDetails>(['assistant', variables.assistant_id, 'details']) ?? null;
      if (variables.assistant_id) {
        client.setQueryData(['assistant', variables.assistant_id, 'details'], mergeLeft(variables));
      }
      return onMutate?.(variables);
    },
    onSuccess(data, variables, context) {
      if (variables.assistant_id) {
        client.invalidateQueries({ queryKey: ['assistant', variables.assistant_id, 'details'] });
        client.invalidateQueries({ queryKey: ['assistant', variables.assistant_id, 'conversation-starters'] });
      }
      return onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      // Rollback for the error
      if (variables.assistant_id && cache.current) {
        client.setQueryData(['assistant', variables.assistant_id, 'details'], cache.current);
      }
      onError?.(error, variables, context);
    },
  });
}

export function useUpdateAssistantMutation({
  onMutate,
  onSuccess,
  ...options
}: AdditionalMutationOptions<Assistant, AssistantUpdate, ['assistant', 'update']> = {}) {
  const cache = useRef<Assistant | null>(null);
  const client = useQueryClient();
  const csrf = useCsrfTokenHeader();
  return useMutation({
    ...options,
    mutationKey: ['assistant', 'update'],
    async mutationFn(variables) {
      return await request(
        {
          pathname: `/api/v2/assistants/${variables.id}`,
        },
        {
          method: 'PATCH',
          body: JSON.stringify(variables),
          headers: csrf,
        },
      );
    },
    onMutate(variables) {
      cache.current = client.getQueryData<Assistant>(['assistants', variables.id]) ?? null;
      if (variables.id) {
        client.setQueryData(['assistants', variables.id], mergeLeft(variables));
      }
      return onMutate?.(variables);
    },
    onSuccess(data, variables, context) {
      client.invalidateQueries({
        queryKey: ['assistants', variables.id],
      });
      onSuccess?.(data, variables, context);
    },
  });
}

type AssistantPermissionReset = {
  assistant_id: string;
  permission: AssistantPermission;
};
export function useResetAssistantPermissionMutation({
  onMutate,
  onError,
  onSuccess,
  ...options
}: AdditionalMutationOptions<
  AssistantPermissionReset,
  AssistantPermissionReset,
  ['assistant', 'permission', 'reset']
> = {}) {
  const csrf = useCsrfTokenHeader();
  const client = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: ['assistant', 'permission', 'reset'],
    async mutationFn(variables) {
      const headers = new Headers(csrf);
      headers.set('Content-Type', 'application/json');
      return request<AssistantPermissionReset>(
        {
          pathname: `/api/v2/assistants/${variables.assistant_id}/reset-permission`,
        },
        {
          method: 'PATCH',
          body: JSON.stringify(variables),
          headers,
        },
      );
    },
    onSuccess(data, variables, context) {
      client.invalidateQueries({
        queryKey: ['assistants', variables.assistant_id],
      });
      return onSuccess?.(data, variables, context);
    },
  });
}

interface DeleteAssistantMutationProps {
  onSuccess?: (data: any, variables: any, context: any) => void;
  onError?: (data: any, variables: any, context: any) => void;
}

export function useDeleteAssistantMutation({ onSuccess, onError }: DeleteAssistantMutationProps) {
  const csrf = useCsrfTokenHeader();
  const client = useQueryClient();

  return useMutation<void, Error, { id: string }>({
    mutationKey: ['assistant', 'delete'],
    mutationFn: async ({ id }) => {
      if (!id) {
        throw new Error('No id provided');
      }
      await request(
        {
          pathname: `/api/v2/assistants/${id}`,
        },
        {
          method: 'DELETE',
          headers: {
            ...csrf,
          },
        },
      );
    },
    onSuccess(data, variables, context) {
      onSuccess?.(data, variables, context);
      console.log('onSuccess.useDeleteAssistantMutation');
      client.invalidateQueries({
        queryKey: ['assistants'],
      });
      client.removeQueries({
        queryKey: ['assistants', variables.id],
      });
      client.removeQueries({
        queryKey: ['assistant', variables.id, 'details'],
      });
    },
    onError(error, variables, context) {
      onError?.(error, variables, context);
    },
  });
}

export function useUpdateAssistantToolMutation({ onSuccess }: DeleteAssistantMutationProps) {
  const csrf = useCsrfTokenHeader();
  const client = useQueryClient();

  return useMutation<void, Error, { id: string; body: AssistantUpdateRequest }>({
    mutationKey: ['assistant', 'delete'],
    mutationFn: async ({ id, body }) => {
      if (!id) {
        throw new Error('No id provided');
      }
      await request(
        {
          pathname: `/api/v2/assistants/${id}/tools`,
        },
        {
          method: 'PUT',
          body: JSON.stringify(body),
          headers: csrf,
        },
      );
    },
    onSuccess(data, variables, context) {
      onSuccess?.(data, variables, context);
      client.invalidateQueries({
        queryKey: ['assistants', 'tools', variables.id],
      });
    },
  });
}
