import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Trigger } from '@radix-ui/react-dropdown-menu';
import { Button } from '../button';
import { cn } from '../utils';

type TriggerProps = ComponentPropsWithoutRef<typeof Button>;
type TriggerRef = ElementRef<typeof Button>;

export const DropdownTrigger = forwardRef<TriggerRef, TriggerProps>(
  ({ children, className, variant = 'outline', ...props }, ref) => {
    return (
      <Trigger
        className={cn(
          'DropdownButton',
          'select-none',
          'flex',
          'gap-2',
          'data-[state=open]:rounded-t-lg',
          'data-[state=open]:rounded-b-none',
          'text-sm',
          'text-left',
          'px-4',
          'justify-start',
          '[&>svg]:h-3',
          '[&>svg]:w-3',
          className,
        )}
        asChild
      >
        <Button size="sm" variant={variant} {...props} ref={ref}>
          {children}
        </Button>
      </Trigger>
    );
  },
);

DropdownTrigger.displayName = 'DropdownTrigger';
