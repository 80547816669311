'use client';

import React, { ComponentPropsWithoutRef, forwardRef, type ReactNode } from 'react';
import NextLink, { LinkProps, type LinkProps as NextLinkProps } from 'next/link';
import { Button, ButtonProps } from '../button';
import invariant from 'tiny-invariant';
import { format } from 'url';
import { cn } from '../utils';
import { usePathname } from 'next/navigation';

interface Match {
  pathname: LinkProps['href'] | null;
}

/**
 * Note that a link should have some sort of label.
 * This can either be a `aria-label` or `children`, but at least one is required
 * Both can be passed, but this is less common
 */
type LabelProps =
  | { 'aria-label': string; children: Exclude<ReactNode, string> }
  | { 'aria-label': never; children: ReactNode }
  | { 'aria-label': string; children: ReactNode };

type MatchProps = {
  /**
   * Used to determine if the link is the current page
   */
  isMatch?: (current: Match, route: Match) => boolean;
  'aria-current'?: HTMLAnchorElement['ariaCurrent'];
};

export type NavLinkProps = NextLinkProps &
  Pick<ButtonProps, 'variant' | 'size'> &
  LabelProps &
  MatchProps &
  ComponentPropsWithoutRef<'a'>;

/**
 * Default function for matching the url
 * Note that a Next link can use either a string or an object for the href.
 * @param pathname the path to match
 */
function isDefaultMatch({ pathname: current }: Match, { pathname }: Match): boolean {
  if (!current || !pathname) return false;
  if (typeof pathname === 'string') {
    return pathname === current;
  }
  return format(pathname) === current;
}

/**
 * Navigation link component
 * This is a wrapper around the Next.js `Link` component, and applies styles based on if the link is the current page
 * This is done via a `aria-current="page"` attribute
 * Generally speaking, this would be used in a sequence of other links, generally in a navigational element
 *
 * @example
 * ```typescriptreact
 * function NavigationBar() {
 *   return (
 *     <nav className="flex items-center gap-2">
 *       <NavLink size="icon" href="/" aria-label="Home">
 *         <HomeIcon />
 *       </NavLink>
 *     </nav>
 *   );
 * }
 * ```
 */
export const NavButton = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (
    {
      href,
      isMatch = isDefaultMatch,
      variant = 'outline',
      size = 'default',
      children,
      'aria-label': ariaLabel,
      'aria-current': ariaCurrent,
      className,
      ...props
    },
    ref,
  ) => {
    const pathname = usePathname();

    invariant(size !== 'icon' || typeof children !== 'string', 'NavLink with size="icon" must have a non-string child');
    invariant(!!children || !!ariaLabel, 'NavLink must have either children or aria-label');

    return (
      <Button size={size} variant={variant} asChild>
        <NextLink
          {...props}
          href={href}
          ref={ref}
          aria-label={ariaLabel}
          aria-current={ariaCurrent ?? isMatch({ pathname }, { pathname: href }) ? 'page' : undefined}
          className={cn(
            '[&>svg]:fill-black-700/40',
            'fill-black-700/40',
            'hover:bg-brand-nav-buttons/5',
            'aria-current-page:text-brand-nav-buttons',
            'aria-current-page:bg-brand-nav-buttons/5',
            'aria-current-page:fill-brand-nav-buttons',
            'aria-current-page:text-white',
            '[&>svg]:aria-current-page:fill-brand-nav-buttons',
            className,
          )}
        >
          {children}
        </NextLink>
      </Button>
    );
  },
);

NavButton.displayName = 'Link';
