import { createContext, useContext } from 'react';

const AssistantContext = createContext<{
  assistant: Maybe<string>;
  setAssistant: (assistantId: Maybe<string>) => void;
}>({
  assistant: undefined,
  setAssistant: (_) => _,
});

export const useActiveAssistantId = () => {
  const context = useContext(AssistantContext);
  if (context === undefined) {
    throw new Error('useAssistant must be used within an AssistantProvider');
  }
  return context.assistant;
};

export default AssistantContext;
