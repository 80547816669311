'use client';

import React, { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import { cn } from '../utils';

export type CheckboxRef = ElementRef<typeof CheckboxPrimitive.Root>;
export type CheckboxProps = ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>;

export const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(({ className, checked, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    checked={checked}
    className={cn(
      'border-primary',
      'data-[state=checked]:bg-blue-500',
      'data-[state=checked]:text-white',
      'data-[state=checked]:text-primary-foreground',
      'peer',
      'h-4',
      'w-4',
      'shrink-0',
      'rounded-sm',
      'border',
      'ring-offset-background',
      'focus-visible:outline-none',
      'focus-visible:ring-2',
      'focus-visible:ring-ring',
      'focus-visible:ring-offset-2',
      'disabled:cursor-not-allowed',
      'disabled:opacity-50',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn('flex', 'items-center', 'justify-center', 'text-current', { 'text-white': checked })}
    >
      <Check className="h-4 w-4 accent-current" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = CheckboxPrimitive.Root.displayName;
