import { mergeLeft } from 'ramda';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdditionalMutationOptions, Personalization, PersonalizationUpdate } from '../types';
import { useRequest } from '../request';

/**
 * Update user personalization
 * @param options
 * @returns
 */
export function useUpdatePersonalizationMutation({
  onSuccess,
  onMutate,
  ...options
}: AdditionalMutationOptions<PersonalizationUpdate, PersonalizationUpdate, ['user', 'personalization']> = {}) {
  const request = useRequest();
  const client = useQueryClient();

  return useMutation({
    ...options,
    mutationKey: ['user', 'personalization'],
    async mutationFn({ personalizations }: PersonalizationUpdate) {
      const { data } = await request<{ data: Personalization }>('/api/v2/personalization', {
        method: 'PATCH',
        body: JSON.stringify({
          personalizations,
        }),
      });
      return data;
    },
    onMutate(variables) {
      client.setQueryData(['user', 'personalization'], mergeLeft(variables));
      return onMutate?.(variables);
    },
    onSuccess(data, variables, context) {
      client.invalidateQueries({
        queryKey: ['user', 'personalization'],
      });
      return onSuccess?.(data, variables, context);
    },
  });
}
