import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Content } from '@radix-ui/react-dropdown-menu';
import { cn } from '../utils';

type ContentProps = ComponentPropsWithoutRef<typeof Content>;
type ContentRef = ElementRef<typeof Content>;

export const DropdownContent = forwardRef<ContentRef, ContentProps>(({ className, ...props }, ref) => {
  return (
    <Content
      {...props}
      className={cn(
        'bg-background',
        'border-input',
        'border',
        'rounded-b-lg',
        'p-[2px]',
        'DropdownMenuContent',
        className,
      )}
      ref={ref}
    />
  );
});

DropdownContent.displayName = 'DropdownContent';
