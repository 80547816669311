import { ComponentPropsWithRef, ElementRef, forwardRef } from 'react';
import { cn } from '../utils';

type FormFooterProps = ComponentPropsWithRef<'footer'>;
type Ref = ElementRef<'footer'>;

export const FormFooter = forwardRef<Ref, FormFooterProps>(({ className, ...props }, ref) => {
  return <footer ref={ref} {...props} className={cn('flex', 'justify-end', 'items-end', 'gap-2')} />;
});

FormFooter.displayName = 'FormFooter';
