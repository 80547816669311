'use client';

import React, { type HTMLAttributes } from 'react';
import { cn } from '../utils';

export const DialogFooter = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <footer
    className={cn('flex', 'flex-col-reverse', 'sm:flex-row', 'sm:justify-end', 'sm:space-x-2', 'px-8', className)}
    {...props}
  />
);

DialogFooter.displayName = 'DialogFooter';
