import React, { ComponentProps, forwardRef } from 'react';
import { Theme } from '@radix-ui/themes';
import { Inter as SansFont } from 'next/font/google';
import { cn } from '../utils';

const sans = SansFont({
  subsets: ['latin'],
  variable: '--font-family-sans',
  fallback: ['system-ui', 'Helvetica Neue', 'Helvetica', 'Arial'],
  weight: ['300', '400', '500', '600', '700', '800'],
  display: 'swap',
});

type ThemeProps = ComponentProps<typeof Theme>;

export const ThemeProvider = forwardRef<HTMLDivElement, ThemeProps>(({ className, ...props }, ref) => {
  return <Theme {...props} accentColor="blue" className={cn(sans.className, sans.variable, className)} ref={ref} />;
});

ThemeProvider.displayName = 'ThemeProvider';
