import { useQuery } from '@tanstack/react-query';
import { request } from '../util';
import { AdditionalQueryOptions } from '../types';

type FeatureFlagOptions = {
  name: string;
};

export function useFeatureFlag(
  name: string,
  options: AdditionalQueryOptions<FeatureFlagOptions, ['feature-flag', string]> = {},
) {
  return useQuery({
    ...options,
    queryKey: ['feature-flag', name],
    queryFn: async () => {
      const { data } = await request<{ data: FeatureFlagOptions }>({
        pathname: `/api/v2/feature-flags/${name}`,
        query: { name },
      });
      return data;
    },
  });
}

export function useFeatureFlags(
  options: AdditionalQueryOptions<Array<{ [key: string]: string }>, ['feature-flags']> = {},
) {
  return useQuery({
    ...options,
    queryKey: ['feature-flags'],
    queryFn: async () => {
      const { data } = await request<{ data: Array<{ [key: string]: string }> }>({
        pathname: `/api/v2/feature-flags`,
      });
      return data;
    },
  });
}
