import { useCallback } from 'react';
import { UrlObject } from 'url';
import useCsrfToken from '~/core/hooks/use-csrf-token';

interface URLParams {
  pathname: string;
  searchParams?: URLSearchParams | Record<string, string>;
}

function toURL({ pathname, searchParams }: URLParams): string {
  const url = new URL(process.env.NEXT_PUBLIC_SITE_URL);
  url.pathname = pathname;
  if (searchParams instanceof URLSearchParams) {
    url.search = searchParams.toString();
  } else if (typeof searchParams === 'object' && searchParams !== null) {
    url.search = new URLSearchParams(searchParams).toString();
  }
  return url.toString();
}

interface RequestParams extends RequestInit {
  searchParams?: URLSearchParams | Record<string, string>;
}

/**
 * Gives you a function to make requests with the CSRF token
 * Handles parsing the pathname into a full URL
 * May be expanded to include any other necessary things in the future
 */
export function useRequest() {
  const csrfToken = useCsrfToken();

  return useCallback(
    async <T>(pathname: string, { headers: initialHeaders, searchParams, ...rest }: RequestParams = {}) => {
      const headers = new Headers(initialHeaders);
      if (csrfToken) {
        headers.set('X-CSRF-Token', csrfToken);
      }
      const response = await fetch(toURL({ pathname, searchParams }), { headers, ...rest });
      return response.json() as Promise<T>;
    },
    [csrfToken],
  );
}
