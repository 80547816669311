'use client';

import React, { type HTMLAttributes, forwardRef } from 'react';
import { cn } from '../utils';
import { Close } from '@radix-ui/react-dialog';
import { X } from 'lucide-react';

export const DialogHeader = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <header
        className={cn(
          'flex',
          'text-center',
          'sm:text-left',
          'px-5',
          'py-3',
          'justify-between',
          'items-center',
          'border-b-2',
          className,
        )}
        {...props}
        ref={ref}
      >
        <span>{children}</span>
        <Close
          className={cn(
            'rounded-sm',
            'opacity-70',
            'ring-offset-background',
            'transition-opacity',
            'hover:opacity-100',
            'focus:outline-none',
            'focus:ring-2',
            'focus:ring-ring',
            'focus:ring-offset-2',
            'disabled:pointer-events-none',
            'data-[state=open]:bg-accent',
            'data-[state=open]:text-muted-foreground',
          )}
        >
          <X className="w-4 h-4" />
          <span className="sr-only">Close</span>
        </Close>
      </header>
    );
  },
);

DialogHeader.displayName = 'DialogHeader';
