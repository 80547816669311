'use client';

import * as React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';

import { cn } from '../utils';
import { useGetComponentConfig } from '@portal/branding/hooks/useGetComponentConfig';
import { useMemo } from 'react';

type ProgressType = 'success' | 'danger' | 'warn' | 'violet';

interface ProgressProps extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
  type?: ProgressType;
  value?: number;
}

const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, ProgressProps>(
  ({ className, type = 'success', value, ...props }, ref) => {
    const { data, isLoading } = useGetComponentConfig<{ className: string }>('components.progress', type === 'violet');
    const classes = useMemo(() => {
      const result: { [key: string]: boolean } = {
        'bg-brand-secondary-green': type === 'success',
        'bg-[#A06409]': type === 'warn',
        'bg-brand-secondary-alert-red': type === 'danger',
      };

      if (type === 'violet' && data?.props.className && !isLoading) {
        result[data.props.className] = true;
      }

      return result;
    }, [data?.props.className, type, isLoading]);

    return (
      <ProgressPrimitive.Root
        ref={ref}
        className={cn('relative h-1 w-full overflow-hidden rounded-[30px] bg-brand-secondary-grey', className)}
        {...props}
      >
        <ProgressPrimitive.Indicator
          className={cn('h-full w-full flex-1 transition-all rounded-full', classes, className)}
          style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
        />
      </ProgressPrimitive.Root>
    );
  },
);

Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
