import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { request } from '../util';
import useCsrfTokenHeader from '~/core/hooks/use-csrf-token-header';
import { AdditionalMutationOptions, Organization } from '../types';
import { useRef } from 'react';
import configuration from '~/configuration';

export type CreateOrganizationResponse = { organizationId: string };

/**
 * Create new organization.
 * @name useCreateOrganizationMutation
 * @param userId  The current user ID.
 * @param onMutate
 * @param onSuccess
 * @returns Mutation object.
 */
export function useCreateOrganizationMutation({
  userId,
  onMutate,
  onSuccess,
}: { userId?: string } & AdditionalMutationOptions<UseMutationOptions, any, ['mutationKey', 'mutationFn']> = {}) {
  const cache = useRef<Organization | null>(null);
  const client = useQueryClient();
  const csrfToken = useCsrfTokenHeader();
  const headers = new Headers(csrfToken);
  headers.set('Content-Type', 'application/x-www-form-urlencoded');

  return useMutation({
    mutationKey: ['organizations', userId, 'create'],
    async mutationFn(organization: string): Promise<CreateOrganizationResponse> {
      return request(
        { pathname: configuration.paths.api.organizations.create },
        { method: 'POST', headers, body: JSON.stringify({ organization }) },
      );
    },
    onMutate(variables: string) {
      cache.current = client.getQueryData<Organization>(['organizations', variables]) ?? null;
      if (variables && cache.current) {
        client.setQueryData(['organizations', variables], cache.current);
      }

      return onMutate?.(variables);
    },
  });
}

/**
 * Create new organization.
 * @name useSetCurrentOrganizationMutation
 * @param organizationId  The organization ID.
 * @param onMutate
 * @param onSuccess
 * @returns Mutation object.
 */
export function useSetCurrentOrganizationMutation({
  onMutate,
  onSuccess,
}: AdditionalMutationOptions<UseMutationOptions, any, ['mutationKey', 'mutationFn']> = {}) {
  const csrfToken = useCsrfTokenHeader();
  const headers = new Headers(csrfToken);
  headers.set('Content-Type', 'application/x-www-form-urlencoded');

  return useMutation({
    mutationKey: ['organizations', 'current'],
    async mutationFn(organizationId: string) {
      return request(
        { pathname: configuration.paths.api.organizations.current.replace('[organization]', organizationId) },
        { method: 'PUT', headers },
      );
    },
    onMutate(variables: string) {
      return onMutate?.(variables);
    },
    onSuccess: (data, variables, context) => {
      // @ts-ignore
      return onSuccess?.(data, variables, context);
    },
  });
}

export function useDeleteOrganizationMutation({
  onError,
}: {
  onError?: (error: Error, variables: { id: string }, context: unknown) => void;
}) {
  const csrf = useCsrfTokenHeader();
  const client = useQueryClient();

  return useMutation<void, Error, { id: string }>({
    mutationKey: ['organization', 'delete'],
    mutationFn: async ({ id }) => {
      if (!id) {
        throw new Error('No organization ID provided');
      }

      await request(
        {
          pathname: configuration.paths.api.organizations.delete.replace('[organization]', id),
        },
        {
          method: 'DELETE',
          headers: {
            ...csrf,
          },
        },
      );
    },
    onSuccess: (_, variables) => {
      client.invalidateQueries({
        queryKey: ['organizations'],
      });
      client.removeQueries({
        queryKey: ['organizations', variables.id],
      });
    },
    onError,
  });
}
