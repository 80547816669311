import React, { ComponentPropsWithoutRef } from 'react';
import { Portal } from '@radix-ui/react-dropdown-menu';
import { Theme } from '@radix-ui/themes';

type DropdownPortalProps = ComponentPropsWithoutRef<typeof Portal>;

export function DropdownPortal({ children, ...props }: DropdownPortalProps) {
  return (
    <Portal {...props}>
      <Theme>{children}</Theme>
    </Portal>
  );
}
