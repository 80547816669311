import { useQuery } from '@tanstack/react-query';
import { AdditionalQueryOptions } from '../../types';
import { AssistantConversationStarters } from '../../types';
import { useRequest } from '../../request';

/**
 * Requests conversation starters
 * will not request if no assistant id is provided (that is, the assistant id must be a string type)
 * @param id
 * @param param1
 * @returns
 */
export function useAssistantConversationStartersQuery(
  id: string,
  {
    enabled = true,
    ...options
  }: AdditionalQueryOptions<AssistantConversationStarters[], ['assistant', string, 'conversation-starters']> = {},
) {
  const request = useRequest();
  return useQuery({
    ...options,
    enabled: enabled && typeof id === 'string',
    queryKey: ['assistant', id, 'conversation-starters'],
    async queryFn() {
      const { data } = await request<{ data: AssistantConversationStarters[] }>(
        `/api/v2/assistants/${id}/conversation-starters`,
        {
          method: 'GET',
        },
      );
      return data;
    },
  });
}
