import React, { cloneElement, ComponentPropsWithoutRef, ElementRef, ElementType, forwardRef } from 'react';
import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import { cn } from '../utils';

interface DropdownItemProps extends ComponentPropsWithoutRef<typeof DropdownMenuItem> {
  /**
   * A destructive action will be highlighted in red and imply to the user that caution is to be taken
   */
  destructive?: boolean;
}
type DropdownItemRef = ElementRef<typeof DropdownMenuItem>;

export const DropdownItem = forwardRef<DropdownItemRef, DropdownItemProps>(
  ({ className, destructive, children, ...props }, ref) => {
    return (
      <DropdownMenuItem
        {...props}
        className={cn(
          'cursor-pointer',
          'px-4',
          'py-2',
          'hover:bg-[#F9F8F5]',
          'dark:hover:bg-[#F9F8F5]/[0.10]',
          'focus-visible:outline-none',
          'transition-colors',
          'rounded',
          'text-sm',
          'flex',
          'gap-2',
          'items-center',
          'font-medium',
          'max-h-7',
          '[&>svg]:h-3',
          '[&>svg]:w-3',
          {
            'text-[#E01919] hover:bg-[#E01919]/[0.10] dark:hover:bg-[#E01919]/[0.10]': destructive,
          },
          className,
        )}
        ref={ref}
      >
        {children}
      </DropdownMenuItem>
    );
  },
);

DropdownItem.displayName = 'DropdownItem';
