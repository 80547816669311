'use client';

import { type ElementRef, type ComponentPropsWithoutRef, forwardRef } from 'react';
import { Content } from '@radix-ui/react-dialog';
import { cn } from '../utils';

export const DialogContent = forwardRef<ElementRef<typeof Content>, ComponentPropsWithoutRef<typeof Content>>(
  ({ className, children, ...props }, ref) => (
    <Content
      ref={ref}
      className={cn(
        'fixed',
        'left-[50%]',
        'top-[50%]',
        'z-50',
        'grid',
        'w-full',
        'max-w-lg',
        'translate-x-[-50%]',
        'translate-y-[-50%]',
        'gap-4',
        'border',
        'bg-background',
        // 'p-6',
        'font-sans',
        'shadow-lg',
        'duration-200',
        'data-[state=open]:animate-in',
        'data-[state=closed]:animate-out',
        'data-[state=closed]:fade-out-0',
        'data-[state=open]:fade-in-0',
        'data-[state=closed]:zoom-out-95',
        'data-[state=open]:zoom-in-95',
        'data-[state=closed]:slide-out-to-left-1/2',
        'data-[state=closed]:slide-out-to-top-[48%]',
        'data-[state=open]:slide-in-from-left-1/2',
        'data-[state=open]:slide-in-from-top-[48%]',
        'sm:rounded-b-lg',
        'flex',
        'flex-col',
        'gap-10',
        'pb-5',
        className,
      )}
      {...props}
    >
      {children}
    </Content>
  ),
);

DialogContent.displayName = Content.displayName;
