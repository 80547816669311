import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import useCsrfTokenHeader from '~/core/hooks/use-csrf-token-header';
import { ConfigResolverPropsType, ConfigResolverReturnType } from '../types';
import { getQueryClient } from '@portal/query';

export const useGetComponentConfig = <P extends ConfigResolverPropsType = ConfigResolverPropsType>(
  key: string,
  isEnabled: boolean = true,
) => {
  const csrfHeaders = useCsrfTokenHeader();
  const client = getQueryClient();

  return useQuery<AxiosResponse<ConfigResolverReturnType<P>>, AxiosError, ConfigResolverReturnType<P>>(
    {
      queryKey: [key],
      queryFn: () =>
        axios.get(`/api/brand-config?key=${key}`, {
          headers: {
            'Content-Type': 'application/json',
            ...csrfHeaders,
          },
        }),
      select: (response) => response.data,
      enabled: isEnabled,
    },
    client,
  );
};
