'use client';

import { Description } from '@radix-ui/react-dialog';
import React, { type ElementRef, type ComponentPropsWithoutRef, forwardRef } from 'react';
import { cn } from '../utils';

type DialogDescriptionProps = ComponentPropsWithoutRef<typeof Description>;

export const DialogDescription = forwardRef<ElementRef<typeof Description>, DialogDescriptionProps>(
  ({ className, ...props }, ref) => (
    <Description ref={ref} className={cn('text-base', 'font-normal', 'px-6', className)} {...props} />
  ),
);

DialogDescription.displayName = Description.displayName;
