'use client';

import { ComponentPropsWithRef, ForwardedRef, forwardRef, ReactNode, useId } from 'react';
import { FieldValues, FormProvider, UseFormReturn, type FormProviderProps } from 'react-hook-form';

type Props<
  T extends FieldValues = FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
> = ComponentPropsWithRef<'form'> & UseFormReturn<T, TContext, TTransformedValues> & FormProviderProps<T>;

export function Container<
  T extends FieldValues = FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  {
    children,
    watch,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    setValue,
    trigger,
    formState,
    resetField,
    reset,
    handleSubmit,
    unregister,
    control,
    register,
    setFocus,
    id,
    ...props
  }: Props<T, TContext, TTransformedValues>,
  ref: ForwardedRef<HTMLFormElement>,
) {
  const formId = useId();
  return (
    <FormProvider
      watch={watch}
      getValues={getValues}
      getFieldState={getFieldState}
      setError={setError}
      clearErrors={clearErrors}
      setValue={setValue}
      trigger={trigger}
      formState={formState}
      resetField={resetField}
      reset={reset}
      handleSubmit={handleSubmit}
      unregister={unregister}
      control={control}
      register={register}
      setFocus={setFocus}
    >
      <form {...props} id={id ?? formId} ref={ref}>
        {children}
      </form>
    </FormProvider>
  );
}

/**
 * A form element directly connected to react-hook-form.
 * Will instance a `FormProvider` so context can connect to it
 * In order to use this, you should use `useForm` from `react-hook-form` then pass the methods down
 *
 * @example
 * ```tsx
 * function LoginForm() {
 *   const methods = useForm({
 *     defaultValues: {
 *       username: '',
 *       password: '',
 *     },
 *   });
 *
 *   const onSubmit = methods.handleSubmit((data) => {
 *     return login(data);
 *   });
 *
 *   return (
 *     <Form {...methods} onSubmit={onSubmit}>
 *       <input {...methods.register('username')} />
 *       <input {...methods.register('password')} />
 *       <button type="submit">Submit</button>
 *     </Form>
 *   );
 * }
 * ```
 */
export const Form = forwardRef<HTMLFormElement, Props<{}, unknown, undefined>>(Container) as <
  T extends FieldValues = FieldValues,
  TContext = unknown,
  TTransformedValues extends FieldValues | undefined = undefined,
>(
  props: Props<T, TContext, TTransformedValues>,
) => ReactNode;
