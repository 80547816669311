'use client';
import React, { forwardRef } from 'react';
import Link from 'next/link';
import Trans from '~/core/ui/Trans';
import { usePathname } from 'next/navigation';
import cn from 'classnames';
import { ButtonBackMobile } from '../button';
import { useSidebarStore } from '~/stores/my-wandy-sidebar';

interface NavItem {
  path: string;
  label: string;
  datapr: string;
}

interface NavigationMenuProps {
  links: NavItem[];
}

export const Navtabs = forwardRef<HTMLDivElement, NavigationMenuProps>(({ links, ...props }, ref) => {
  const pathname = usePathname() ?? '';
  const { isMobileView } = useSidebarStore();
  return (
    <div
      className={cn({
        'flex items-center pl-4': isMobileView,
      })}
    >
      {isMobileView && <ButtonBackMobile activeMenuItem={'settings'} />}
      <nav ref={ref} className="flex font-bold text-base leading-3 border-b border-panel-gray shadow-sm" {...props}>
        {links?.map((item, index) => {
          const isActive = pathname === item.path;
          return (
            <Link
              href={item.path}
              key={index}
              className={cn(
                'p-5',
                'hover:text-black-700',
                isActive ? 'border-b-[3px] border-solid border-b-brand-nav-tabs text-black-700' : 'text-black-700/30',
              )}
              data-pr={item.datapr}
            >
              <Trans i18nKey={item.label} />
            </Link>
          );
        })}
      </nav>
    </div>
  );
});

Navtabs.displayName = 'Navtabs';
