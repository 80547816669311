'use client';

import { type FieldValues, type FieldPath } from 'react-hook-form';
import {
  createContext,
  useContext,
  ComponentPropsWithRef,
  ForwardedRef,
  useId,
  useMemo,
  type ReactNode,
  forwardRef,
} from 'react';

interface FormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends ComponentPropsWithRef<'div'> {
  name: TName;
}

interface FormFieldContext<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName | undefined;
  inputId: string | undefined;
}

const FormFieldContext = createContext<FormFieldContext>({
  name: undefined,
  inputId: undefined,
});

export function useFormField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>() {
  const context = useContext(FormFieldContext) as FormFieldContext<TFieldValues, TName>;
  return context;
}

/**
 * FormField is used to wrap form controls and provide context on the field with that name
 */
function Container<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ name, ...props }: FormFieldProps<TFieldValues, TName>, ref: ForwardedRef<HTMLDivElement>) {
  const id = useId();

  const value = useMemo(() => ({ name, inputId: id }), [name, id]) as FormFieldContext<TFieldValues, TName>;

  return (
    <FormFieldContext.Provider value={value}>
      <div role="group" {...props} ref={ref} />
    </FormFieldContext.Provider>
  );
}

export const FormField = forwardRef<HTMLDivElement, FormFieldProps<FieldValues, FieldPath<FieldValues>>>(Container) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: FormFieldProps<TFieldValues, TName>,
) => ReactNode;
